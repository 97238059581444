<template>
  <div class="flex w-full">
    <div
      class="valueBackground w-1/12 historyNoWidth w-full"
      @click="openOrderDetail()"
    >
      <p>{{ report.id }}</p>
    </div>
    <div
      class="valueBackground historyCompanyWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ report.company }}</p>
    </div>
    <div
      class="valueBackground historyOwnerWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ report.owner }}</p>
    </div>
    <div
      class="valueBackground historyPatientWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ report.patient }}</p>
    </div>
    <div
      class="valueBackground historyCreditWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p>{{ report.credit }}</p>
    </div>
    <div
      class="valueBackground historyReadyDateWidth w-1/12 w-full"
      @click="openOrderDetail()"
    >
      <p class="orderDateP">
        {{ getRelativeTime(report.ready_date) }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {};
  },
  props: ["report"],
  methods: {
    openOrderDetail() {
      var queryData = {
        id: this.report.id,
      };

      this.$router
        .push({
          name: "provider_history_detail",
          query: queryData,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    getRelativeTime(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    getOrderStatus(status) {
      if (status == "S") {
        return this.$t("Sent");
      } else if (status == "P") {
        return this.$t("Processing");
      } else if (status == "R") {
        return this.$t("Ready");
      } else if (status == "W") {
        return this.$t("Waiting");
      } else if (status == "C") {
        return this.$t("Cancelled");
      } else {
        return this.$t("New");
      }
    },
    getOrderStatusColor(status) {
      if (status == "W") return "#389CA3";
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "S") return "#31bdf5";
      if (status == "C") return "danger";
      return "primary";
    },
  },
};
</script>

<style lang="scss" scoped>
.valueBackground {
  background-color: white;
  padding: 10px 15px;
  .p {
    font-size: 1rem;
  }
}
</style>