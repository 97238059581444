<template>
  <div class="">
    <vs-popup
      class="pdfClass"
      fullscreen
      :title="$t('Summary')"
      :active.sync="pdfEnable"
    >
      <vs-row vs-type="flex" vs-justify="center">
        <div
          ref="contentPDF"
          style="background-color: #fff; width: 720px; height: 1050px"
        >
          <div>
            <p
              class="mt-6 mb-4 flex justify-center"
              style="font-size: 2rem; font-weight: 800; color: black"
            >
              {{ pdfTitle }}
            </p>
          </div>
          <div>
            <p
              class="mt-6 mb-4 flex justify-end"
              style="font-size: 1.5rem; font-weight: 800; color: black"
            >
              {{ $t("Total") }} {{ $t("Credit") }} : {{ grantTotalCredit }}
            </p>
          </div>

          <div class="w-full mt-4 PdfTitles" style="display: inline-flex">
            <div style="width: 70px" class="fullBorder">
              <p class="text-center">{{ $t("No_Number") }}</p>
            </div>
            <div style="width: 150px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Company") }}</p>
            </div>
            <div style="width: 150px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Owner") }}</p>
            </div>
            <div style="width: 170px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Patient") }}</p>
            </div>
            <div style="width: 80px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Credit") }}</p>
            </div>
            <div style="width: 100px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Ready Date") }}</p>
            </div>
          </div>

          <div
            v-for="report in historyList.slice(0, 35)"
            :key="report.id"
            class="w-full"
            style="display: inline-flex"
          >
            <div style="width: 70px" class="fullBorderWithoutTop">
              <p class="text-center">{{ report.id }}</p>
            </div>
            <div style="width: 150px" class="fullBorderWithoutLeftTop">
              <p class="text-center">{{ report.company }}</p>
            </div>
            <div style="width: 150px" class="fullBorderWithoutLeftTop">
              <p class="text-center">{{ report.owner }}</p>
            </div>
            <div style="width: 170px" class="fullBorderWithoutLeftTop">
              <p class="text-center">{{ report.patient }}</p>
            </div>
            <div style="width: 80px" class="fullBorderWithoutLeftTop">
              <p class="text-center">{{ report.credit }}</p>
            </div>
            <div style="width: 100px" class="fullBorderWithoutLeftTop">
              <p class="text-center">
                {{ getRelativeTimeJustDate(report.ready_date) }}
              </p>
            </div>
          </div>
        </div>
        <vs-divider> </vs-divider>

        <div
          ref="contentPDF2"
          style="background-color: #fff; width: 760px; height: 1080px"
        >
          <div class="w-full PdfTitles" style="display: inline-flex">
            <div style="width: 70px" class="fullBorder">
              <p class="text-center">{{ $t("No_Number") }}</p>
            </div>
            <div style="width: 150px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Company") }}</p>
            </div>
            <div style="width: 150px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Owner") }}</p>
            </div>
            <div style="width: 170px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Patient") }}</p>
            </div>
            <div style="width: 80px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Credit") }}</p>
            </div>
            <div style="width: 100px" class="fullBorderWithoutLeft">
              <p class="text-center">{{ $t("Ready Date") }}</p>
            </div>
          </div>
          <div
            v-for="report in historyList2"
            :key="report.id"
            class="w-full"
            style="display: inline-flex"
          >
            <div style="width: 70px" class="fullBorderWithoutTop">
              <p class="text-center">{{ report.id }}</p>
            </div>
            <div style="width: 150px" class="fullBorderWithoutLeftTop">
              <p class="text-center">{{ report.company }}</p>
            </div>
            <div style="width: 150px" class="fullBorderWithoutLeftTop">
              <p class="text-center">{{ report.owner }}</p>
            </div>
            <div style="width: 170px" class="fullBorderWithoutLeftTop">
              <p class="text-center">{{ report.patient }}</p>
            </div>
            <div style="width: 80px" class="fullBorderWithoutLeftTop">
              <p class="text-center">{{ report.credit }}</p>
            </div>
            <div style="width: 100px" class="fullBorderWithoutLeftTop">
              <p class="text-center">
                {{ getRelativeTimeJustDate(report.ready_date) }}
              </p>
            </div>
          </div>
        </div>
      </vs-row>
    </vs-popup>
    <div class="boxShadow flex items-center">
      <div class="flex items-center p-1 w-full">
        <div class="flex flex-wrap p-2">
          <v-select
            @input="
              errorMessageEnable = false;
              getReport();
            "
            class="selectBoxes m-1"
            v-model="selectedYear"
            :options="yearOptions"
            :placeholder="$t('Year')"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          />
          <v-select
            @input="
              errorMessageEnable = false;
              getReport();
            "
            class="selectBoxes m-1"
            v-model="selectedMonth"
            :options="monthOptions"
            :placeholder="$t('Month')"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
          />

          <vs-alert :active="errorMessageEnable" color="danger">
            {{ errorMessage }}
          </vs-alert>
        </div>
      </div>
    </div>

    <div
      v-if="historyList.length"
      class="boxShadow mt-4"
      style="background-color: white"
    >
      <div class="flex justify-between items-center p-2">
        <p style="font-size: 18px; font-weight: 600">
          {{ $t("Total") }} {{ $t("Credit") }}: {{ grantTotalCredit }}
        </p>
        <vs-button class="mt-2" @click="preparingImages">{{
          $t("Export")
        }}</vs-button>
      </div>
      <div class="scroolHorizontal mt-2">
        <div class="w-full flex">
          <div class="tabHeader historyNoWidth w-1/12 w-full">
            {{ $t("No_Number") }}
          </div>
          <div class="tabHeader historyCompanyWidth w-1/12 w-full">
            {{ $t("Company") }}
          </div>
          <div class="tabHeader historyOwnerWidth w-1/12 w-full">
            {{ $t("Owner") }}
          </div>
          <div class="tabHeader historyPatientWidth w-1/12 w-full">
            {{ $t("Patient") }}
          </div>
          <div class="tabHeader historyCreditWidth w-1/12 w-full">
            {{ $t("Credit") }}
          </div>
          <div class="tabHeader historyReadyDateWidth w-1/12 w-full">
            {{ $t("Ready Date") }}
          </div>
        </div>

        <reportList
          class="orderBar"
          v-for="report in historyList"
          :report="report"
          :key="report.id"
        />
      </div>
      <div class="flex justify-between items-center p-2">
        <p style="font-size: 18px; font-weight: 600">
          {{ $t("Total") }} {{ $t("Credit") }}: {{ grantTotalCredit }}
        </p>
        <vs-button @click="preparingImages">{{ $t("Export") }}</vs-button>
      </div>
    </div>
    <div v-else class="boxShadow mt-4 w-full">
      <div
        class="flex justify-center items-center h-full"
        style="height: 60vh !important; font-weight: 700; font-size: 1.5rem"
      >
        {{ $t("Provider_Message_1") }}
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import vSelect from "vue-select";
import moment from "moment";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

import reportList from "./components/ReportList";

export default {
  data() {
    return {
      pdfTitle: "",
      delayDoImage: 500,
      pdfEnable: false,
      exportEnable: false,
      errorMessage: "",
      errorMessageEnable: false,
      selectedYear: null,
      yearOptions: [
        {
          id: "2021",
          label: "2021",
        },
        {
          id: "2020",
          label: "2020",
        },
      ],
      selectedMonth: null,
      monthOptions: [
        {
          id: "1",
          label: this.$t("January"),
        },
        {
          id: "2",
          label: this.$t("February"),
        },
        {
          id: "3",
          label: this.$t("March"),
        },
        {
          id: "4",
          label: this.$t("April"),
        },
        {
          id: "5",
          label: this.$t("May"),
        },
        {
          id: "6",
          label: this.$t("June"),
        },
        {
          id: "7",
          label: this.$t("July"),
        },
        {
          id: "8",
          label: this.$t("August"),
        },
        {
          id: "9",
          label: this.$t("September"),
        },
        {
          id: "10",
          label: this.$t("October"),
        },
        {
          id: "11",
          label: this.$t("November"),
        },
        {
          id: "12",
          label: this.$t("December"),
        },
      ],
      grantTotalCredit: 0,
      historyList: [],
      historyList2: [],
      pdfImages: [],
      pdfPageSize: 0,
    };
  },
  methods: {
    preparingImages() {
      if (
        this.selectedYear == null ||
        this.selectedMonth == null ||
        !this.exportEnable
      ) {
        this.errorMessage = this.$t("Provider_Message_5");
        this.errorMessageEnable = true;
        window.scrollTo(0, 0);
        return;
      }
      this.pdfTitle = this.selectedMonth.label + "  " + this.selectedYear.label;
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });
      this.pdfEnable = true;
      var productCountStart = 35;
      var productCount = 40;
      this.pdfPageSize = Math.floor(this.historyList.length / 40) + 1;
      for (let index = 0; index < this.pdfPageSize; index++) {
        setTimeout(() => {
          this.pdfImages.push(new Image());
          let that = this;
          if (index == 0) {
            domtoimage.toPng(this.$refs.contentPDF).then(function (dataUrl) {
              that.pdfImages[index].src = dataUrl;
            });
          } else {
            this.historyList2 = this.historyList.slice(
              productCountStart + productCount * (index - 1),
              productCount * index + productCountStart
            );
            domtoimage.toPng(this.$refs.contentPDF2).then(function (dataUrl) {
              that.pdfImages[index].src = dataUrl;
            });
          }
          if (index + 1 == this.pdfPageSize) {
            this.exportPDF();
          }
        }, 500);
      }
    },
    exportPDF() {
      var that = this;
      setTimeout(() => {
        try {
          const doc = new jsPDF({
            orientation: "portrait",
          });
          var img = new Image();
          img.src = this.pdfImages[0].src;
          doc.addImage(img, "JPEG", 10, 5);
          doc.setFontSize(10);
          doc.text("Page: " + 1, 100, 285);

          for (let index = 1; index < this.pdfPageSize; index++) {
            doc.addPage();
            var img = new Image();
            img.src = this.pdfImages[index].src;
            doc.addImage(img, "JPEG", 10, 5);
            doc.setFontSize(10);
            doc.text("Page: " + (index + 1), 100, 285);
          }
          const filename =
            this.selectedYear.label + "-" + this.selectedMonth.label + ".pdf";
          doc.save(filename);
          this.pdfDownloaded();
        } catch (error) {
          that.exportPDF();
        }
      }, 0);
    },
    pdfDownloaded() {
      this.pdfEnable = false;
      this.$vs.loading.close();
    },

    getRelativeTimeJustDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    getReport() {
      if (this.selectedYear == null && this.selectedMonth == null) {
        this.errorMessage = this.$t("Provider_Message_2");
        this.errorMessageEnable = true;
        return;
      } else if (this.selectedYear == null) {
        return;
      } else if (this.selectedMonth == null) {
        return;
      }
      this.exportEnable = true;
      var params = {
        y: this.selectedYear.id,
        m: this.selectedMonth.id,
      };
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.get(
        API.BASEURL + API.DENTAL_PROVIDER_ORDERS_REPORT,
        this.handleGetHistory,
        params
      );
    },
    getHistory() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.get(
        API.BASEURL + API.DENTAL_PROVIDER_ORDERS_REPORT,
        this.handleGetHistory
      );
    },
    handleGetHistory(status, data) {
      this.$vs.loading.close();

      if (status >= 200 && status < 300) {
        this.historyList = data.results;
        this.grantTotalCredit = data.grant_total_credit;
      }
    },
  },
  created() {
    this.getHistory();
  },
  components: {
    reportList,
    vSelect,
  },
};
</script>

<style lang="scss" scoped>
.fullBorder {
  border: 2px solid #aaa;
}
.fullBorderWithoutTop {
  border-right: 2px solid #aaa;
  border-left: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
}
.fullBorderWithoutLeft {
  border-right: 2px solid #aaa;
  border-top: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
}
.fullBorderWithoutLeftTop {
  border-right: 2px solid #aaa;
  border-bottom: 2px solid #aaa;
}
.boxShadow {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
}
.orderBar {
  cursor: pointer;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0px 3px 10px 0px #ccc3;
    transition: all 0.5s;
  }
}
.scroolHorizontal {
  width: 100%;
  overflow: auto;
}
.tabHeader {
  padding: 10px 15px;
  border: 0;
  text-align: left;
  font-size: 0.85rem;
  font-weight: 800;
  background-color: white;
}
.selectBoxes {
  min-width: 150px;
}
.PdfTitles {
  font-weight: 600;
  color: black;
}
</style>

<style>
.historyNoWidth {
  max-width: 80px;
  min-width: 60px;
}
.historyCompanyWidth {
  max-width: 250px;
  min-width: 200px;
}
.historyOwnerWidth {
  max-width: 400px;
  min-width: 300px;
}
.historyPatientWidth {
  max-width: 400px;
  min-width: 300px;
}
.historyCreditWidth {
  max-width: 90px;
  min-width: 60px;
}
.historyReadyDateWidth {
  /* max-width: 200px; */
  min-width: 100px;
}
.vs__dropdown-option--highlight {
  background: #c89e82 !important;
  color: #fff;
}
.btn-group button {
  padding: 5px 7px 5px 7px !important;
  border: 1px solid #c89e82;
}
.v-select .vs__dropdown-toggle .vs__actions .vs__clear {
  display: none;
}
</style>
